import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { getTransactionAndUserAnalytics } from "../redux/apis/apisCrud";
import loaderGif from "../assets/images/loader.gif";
const Cards = () => {
  const [getTransactionData, setGetTransactionData] = useState<any>("");
  const [loader, setLoader] = useState<any>(false);
  const data: any = {
    graphData: {
      "2024-05-01": [
        {
          volume: {
            totalAmount: "404556.969553",
          },
          network: {
            _id: "63f77721956e88154f5b3ecb",
            name: "sample",
            nameInLower: "arbitrum",
            networkShortName: "arb",
            ferrumNetworkIdentifier: "42161",
            chainId: "42161",
            networkId: "42161",
            rpcUrl:
              "https://nd-499-825-018.p2pify.com/5d8bab30e1462f48144c36f18d2ee958",
            blockExplorerUrl: "https://arbiscan.io",
            networkCurrencySymbol: "ETH",
            dexInputCurrencySymbolList: ["ETH"],
            isTestnet: false,
            isActive: true,
            isAllowedOnGateway: true,
            isAllowedOnMultiSwap: true,
            logo: "https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg",
            publicRpcUrl: "publicRpcUrl",
            backupRpcUrl: "backupRpcUrl",
            positionForMultiSwap: 0,
            multiSwapFiberRouterSmartContractAddress:
              "0xb73A2a6cd7297844B1CbfcFfEb2884bEaf4C22B1",
            isNonEVM: false,
            createdAt: "2023-02-23T14:24:33.200Z",
            updatedAt: "2023-02-08T16:42:41.786Z",
            parentId: null,
            networkCurrencyAddressByNetwork: null,
            user: "6204f7be81113b1c740e5432",
            __v: 0,
            multiswapNetworkFIBERInformation: "64243254655a734d5b51dc9b",
            isAllowedDynamicGasValues: true,
            threshold: 5,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
      ],
      "2024-05-07": [
        {
          volume: {
            totalAmount: "589626",
          },
          network: {
            publicRpcUrl: "",
            backupRpcUrl: "",
            positionForMultiSwap: 0,
            _id: "61f17e726cc928420e0023f7",
            name: "sample2",
            nameInLower: "bsc",
            networkShortName: "BSC",
            ferrumNetworkIdentifier: "56",
            chainId: "56",
            networkId: "56",
            rpcUrl: "https://bsc-dataseed.binance.org/",
            blockExplorerUrl: "https://bscscan.com/",
            dexInputCurrencySymbolList: ["BNB"],
            isTestnet: false,
            isActive: true,
            createdAt: "2022-01-26T17:01:38.447Z",
            updatedAt: "2022-03-20T20:39:46.439Z",
            parentId: null,
            user: "61a6553288dd8363bb954c7a",
            __v: 0,
            networkCurrencySymbol: "BNB",
            isAllowedOnGateway: true,
            networkCurrencyAddressByNetwork: "622f6c497a0cc65e0dee643c",
            contractAddress: "0x8e01cc26d6dd73581347c4370573ce9e59e74802",
            isAllowedOnMultiSwap: true,
            logo: "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/ic_bsc.png",
            multiSwapFiberRouterSmartContractAddress:
              "0x4B87Ab46B56990Aff03dAD1caFEb33e760879d97",
            multiswapNetworkFIBERInformation: "63c938d4fc4a2805e65e1af5",
            isNonEVM: false,
            isAllowedDynamicGasValues: true,
            threshold: 5,
          },
          date: "2024-05-07T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "121367.6908658",
          },
          network: {
            _id: "63f77721956e88154f5b3ecb",
            name: "sample",
            nameInLower: "arbitrum",
            networkShortName: "arb",
            ferrumNetworkIdentifier: "42161",
            chainId: "42161",
            networkId: "42161",
            rpcUrl:
              "https://nd-499-825-018.p2pify.com/5d8bab30e1462f48144c36f18d2ee958",
            blockExplorerUrl: "https://arbiscan.io",
            networkCurrencySymbol: "ETH",
            dexInputCurrencySymbolList: ["ETH"],
            isTestnet: false,
            isActive: true,
            isAllowedOnGateway: true,
            isAllowedOnMultiSwap: true,
            logo: "https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg",
            publicRpcUrl: "publicRpcUrl",
            backupRpcUrl: "backupRpcUrl",
            positionForMultiSwap: 0,
            multiSwapFiberRouterSmartContractAddress:
              "0xb73A2a6cd7297844B1CbfcFfEb2884bEaf4C22B1",
            isNonEVM: false,
            createdAt: "2023-02-23T14:24:33.200Z",
            updatedAt: "2023-02-08T16:42:41.786Z",
            parentId: null,
            networkCurrencyAddressByNetwork: null,
            user: "6204f7be81113b1c740e5432",
            __v: 0,
            multiswapNetworkFIBERInformation: "64243254655a734d5b51dc9b",
            isAllowedDynamicGasValues: true,
            threshold: 5,
          },
          date: "2024-05-07T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "117866",
          },
          network: {
            isAllowedOnGateway: false,
            publicRpcUrl: "",
            backupRpcUrl: "",
            positionForMultiSwap: 0,
            isNonEVM: false,
            _id: "61f182eb6cc928420e002401",
            name: "sample1",
            nameInLower: "ethereum",
            networkShortName: "Ethereum",
            ferrumNetworkIdentifier: "1",
            chainId: "1",
            networkId: "1",
            rpcUrl:
              "https://nd-294-644-098.p2pify.com/a10c91aef3a8c2e65c40afd72050a82b",
            blockExplorerUrl: "https://etherscan.io",
            dexInputCurrencySymbolList: ["ETH"],
            isTestnet: false,
            isActive: true,
            createdAt: "2022-01-26T17:20:43.676Z",
            updatedAt: "2022-01-26T17:31:06.271Z",
            parentId: null,
            user: "61a6553288dd8363bb954c7a",
            __v: 0,
            networkCurrencySymbol: "ETH",
            isAllowedOnMultiSwap: true,
            logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
            multiswapNetworkFIBERInformation: "65d8603bf63126072df33e14",
            multiSwapFiberRouterSmartContractAddress:
              "0x7FfD92b850c5660FB40BD5Efa4f24eb22665E4c4",
            isAllowedDynamicGasValues: false,
            threshold: 5,
          },
          date: "2024-05-07T00:00:00.000Z",
        },
      ],
      "2024-05-06": [
        {
          volume: {
            totalAmount: "589626",
          },
          network: {
            publicRpcUrl: "",
            backupRpcUrl: "",
            positionForMultiSwap: 0,
            _id: "61f17e726cc928420e0023f7",
            name: "sample2",
            nameInLower: "bsc",
            networkShortName: "BSC",
            ferrumNetworkIdentifier: "56",
            chainId: "56",
            networkId: "56",
            rpcUrl: "https://bsc-dataseed.binance.org/",
            blockExplorerUrl: "https://bscscan.com/",
            dexInputCurrencySymbolList: ["BNB"],
            isTestnet: false,
            isActive: true,
            createdAt: "2022-01-26T17:01:38.447Z",
            updatedAt: "2022-03-20T20:39:46.439Z",
            parentId: null,
            user: "61a6553288dd8363bb954c7a",
            __v: 0,
            networkCurrencySymbol: "BNB",
            isAllowedOnGateway: true,
            networkCurrencyAddressByNetwork: "622f6c497a0cc65e0dee643c",
            contractAddress: "0x8e01cc26d6dd73581347c4370573ce9e59e74802",
            isAllowedOnMultiSwap: true,
            logo: "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/ic_bsc.png",
            multiSwapFiberRouterSmartContractAddress:
              "0x4B87Ab46B56990Aff03dAD1caFEb33e760879d97",
            multiswapNetworkFIBERInformation: "63c938d4fc4a2805e65e1af5",
            isNonEVM: false,
            isAllowedDynamicGasValues: true,
            threshold: 5,
          },
          date: "2024-05-07T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "121367.6908658",
          },
          network: {
            _id: "63f77721956e88154f5b3ecb",
            name: "sample",
            nameInLower: "arbitrum",
            networkShortName: "arb",
            ferrumNetworkIdentifier: "42161",
            chainId: "42161",
            networkId: "42161",
            rpcUrl:
              "https://nd-499-825-018.p2pify.com/5d8bab30e1462f48144c36f18d2ee958",
            blockExplorerUrl: "https://arbiscan.io",
            networkCurrencySymbol: "ETH",
            dexInputCurrencySymbolList: ["ETH"],
            isTestnet: false,
            isActive: true,
            isAllowedOnGateway: true,
            isAllowedOnMultiSwap: true,
            logo: "https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg",
            publicRpcUrl: "publicRpcUrl",
            backupRpcUrl: "backupRpcUrl",
            positionForMultiSwap: 0,
            multiSwapFiberRouterSmartContractAddress:
              "0xb73A2a6cd7297844B1CbfcFfEb2884bEaf4C22B1",
            isNonEVM: false,
            createdAt: "2023-02-23T14:24:33.200Z",
            updatedAt: "2023-02-08T16:42:41.786Z",
            parentId: null,
            networkCurrencyAddressByNetwork: null,
            user: "6204f7be81113b1c740e5432",
            __v: 0,
            multiswapNetworkFIBERInformation: "64243254655a734d5b51dc9b",
            isAllowedDynamicGasValues: true,
            threshold: 5,
          },
          date: "2024-05-07T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "117866",
          },
          network: {
            isAllowedOnGateway: false,
            publicRpcUrl: "",
            backupRpcUrl: "",
            positionForMultiSwap: 0,
            isNonEVM: false,
            _id: "61f182eb6cc928420e002401",
            name: "sample1",
            nameInLower: "ethereum",
            networkShortName: "Ethereum",
            ferrumNetworkIdentifier: "1",
            chainId: "1",
            networkId: "1",
            rpcUrl:
              "https://nd-294-644-098.p2pify.com/a10c91aef3a8c2e65c40afd72050a82b",
            blockExplorerUrl: "https://etherscan.io",
            dexInputCurrencySymbolList: ["ETH"],
            isTestnet: false,
            isActive: true,
            createdAt: "2022-01-26T17:20:43.676Z",
            updatedAt: "2022-01-26T17:31:06.271Z",
            parentId: null,
            user: "61a6553288dd8363bb954c7a",
            __v: 0,
            networkCurrencySymbol: "ETH",
            isAllowedOnMultiSwap: true,
            logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
            multiswapNetworkFIBERInformation: "65d8603bf63126072df33e14",
            multiSwapFiberRouterSmartContractAddress:
              "0x7FfD92b850c5660FB40BD5Efa4f24eb22665E4c4",
            isAllowedDynamicGasValues: false,
            threshold: 5,
          },
          date: "2024-05-07T00:00:00.000Z",
        },
      ],
    },
  };
  function convertToUpperFormat(graphData: any) {
    const upperFormat: any = [];

    // Iterate through each date in graphData
    for (const date in graphData) {
      if (graphData?.hasOwnProperty(date)) {
        // Iterate through each entry for the current date
        graphData[date].forEach((entry: any) => {
          const amount = entry.volume.totalAmount;
          const name = entry.network.name;
          const logo = entry.network.logo;

          // Push an object with date, amount, and name into upperFormat array
          upperFormat.push({
            date: date,
            amount: amount,
            name: name,
            logo: logo,
          });
        });
      }
    }

    return upperFormat;
  }
  const getTransactionDataApi = () => {
    getTransactionAndUserAnalytics()
      .then((response) => {
        console.log(response?.data?.body?.data, "resposne123");
        setGetTransactionData(response?.data?.body?.data);
        setLoader(true);
      })
      .catch((error) => {
        console.log(error);
        setLoader(true);
      });
  };
  useEffect(() => {
    getTransactionDataApi();
  }, []);
  const upperFormatData = convertToUpperFormat(
    getTransactionData && getTransactionData?.graphData
      ? getTransactionData && getTransactionData?.graphData
      : data.graphData
  );
  const { graphData } =
    getTransactionData && getTransactionData
      ? getTransactionData && getTransactionData
      : data;
  const firstObjectValue = Object.values(
    getTransactionData && getTransactionData?.graphData
  )[0]
    ? Object.values(getTransactionData && getTransactionData?.graphData)[0]
    : Object.values(data && data?.graphData)[0];
  const graphDataArray = Object.entries(graphData).map(([date, values]) => {
    return { date, values };
  });
  const graphDataArray2 = Object.entries(graphData).map(([date]) => {
    return date;
  });
  console.log(
    // graphDataArray,
    // firstObjectValue,
    // "graphDataArray",
    graphDataArray2
  );
  graphDataArray2.map((item) => {
    console.log(
      "---->>>>",
      graphDataArray.filter((data) => data.date != item)
    );
    // graphDataArray.filter((data) => data.date != item).keys;
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function mapRows(values: any) {
    let arr: any = [];
    values.map((item: any) => {
      console.log("--", item.network.name, item);
      arr.push(item.network.name);
    });
    return arr;
  }
  const uniqueDates = Array.from(
    new Set(upperFormatData.map((item: any) => item.date))
  );
  const filteredData = upperFormatData.reduce((acc: any, curr: any) => {
    const existingEntry = acc.find((item: any) => item.name === curr.name);
    if (existingEntry) {
      existingEntry.amounts.push(curr.amount);
    } else {
      acc.push({ name: curr.name, amounts: [curr.amount], logo: curr.logo });
    }
    return acc;
  }, []);

  console.log(filteredData, "filteredData");
  const seriesData = filteredData.map((item: any, index: any) => ({
    name: item.name,
    type: "line",
    symbol: "circle",
    smooth: true,
    symbolSize: 1,
    data: item.amounts.map((item: any) => item), // Map destination amount to data
    itemStyle: {
      // You can customize item style here if needed
    },
  }));

  // const seriesData = [
  //   {
  //     symbolSize: 1,
  //     data: graphDataArray.map((item) => ({
  //       value: item.values.map((item: any) => item.volume.totalAmount),
  //       name: item.values.map((item: any) => item.network.name),
  //     })),
  //     // name: data.map((item) => item.currency.name),
  //     type: "pie",
  //     radius: ["80%"],
  //     center: isMobile ? ["50%", "50%"] : ["50%", "50%"],
  //     avoidLabelOverlap: false,
  //     label: {
  //       show: false,
  //       position: "center",
  //     },
  //     emphasis: {
  //       label: {
  //         show: true,
  //         fontSize: 40,
  //         fontWeight: "bold",
  //       },
  //     },
  //     labelLine: {
  //       show: false,
  //     },
  //   },
  // ];
  const systemLineGraph = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      orient: "vertical", // Set legend orientation to vertical
      left: "150", // Position legend on the left side
      top: "120",
      textStyle: {
        // Customize text style
        color: "#FFFFFF", // Set text color
        fontSize: 12, // Set text font size
        fontWeight: "normal", // Set text font weight
        fontFamily: "FRM-Aber Mono",
      },
      borderRadius: 10,
      icon: "square",
      itemGap: 10, // Set gap between legend items
      itemWidth: 10, // Set width of legend symbol
      itemHeight: 10,
      backgroundColor: "#1A1D1F", // Set background color of legend
      // Set border radius of legend background
      padding: [10, 10, 10, 10], // Set padding of legend background
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: false, // Hide the axis line
      },
      axisLabel: {
        show: false, // Hide the axis labels
      },
      splitLine: {
        show: true, // Hide the split line
        lineStyle: {
          fontFamily: "FRM-Aber Mono",
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 1,
                color: "#1A1D1F", // Start color
              },
              {
                offset: 0,
                color: "rgba(86, 93, 99, 0)", // End color
              },
            ],
          },
        },
      },
    },
    xAxis: {
      // Add an empty xAxis configuration
      type: "category",
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true, // Hide the split line
        lineStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 1,
                color: "#1A1D1F", // Start color
              },
              {
                offset: 0,
                color: "rgba(86, 93, 99, 0)", // End color
              },
            ],
          },
        },
      },
      data: uniqueDates, // You can leave it empty if you don't want specific x-axis labels
    },
    series: seriesData,
  };
  return (
    <div className="col-12 mt-4 d-flex justify-content-center">
      <div className="col-md-11 col-12 mobile-responsive">
        <div className="col-md-6 col-12">
          <div className="p-5">
            <div className={isMobile ? "" : "p-3"}>
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: "400",
                  color: "#D9B373",
                }}
              >
                Multichain Swap Protocol
              </div>
              <div
                style={{
                  fontSize: "50px",
                  fontWeight: "400",
                  color: "#FFFFFF",
                }}
                className="mt-2"
              >
                Real-Time Analytics
              </div>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "#FFFFFF",
                  lineHeight: "24px",
                }}
                className="mt-4"
              >
                Gain real-time insights into multiswap transactions and trends
                across multiple blockchains
              </div>
            </div>
          </div>
        </div>
        {!loader && (
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img src={loaderGif} alt="" height={60} />
          </div>
        )}
        {loader && (
          <div className="col-md-6 col-12">
            <div className="col-12 d-flex justify-content-center">
              <div className="col-md-10 col-12 mobile-responsive align-items-center">
                {" "}
                <div
                  className="col-md-4 col-12"
                  style={
                    !isMobile
                      ? {
                          color: "#FCFCFC",
                          fontSize: "14px",
                          fontWeight: "400",
                        }
                      : {
                          color: "#FCFCFC",
                          fontSize: "14px",
                          fontWeight: "400",
                          paddingLeft: "25px",
                        }
                  }
                >
                  TRANSACTIONS
                </div>
                <div className="col-md-8 col-11 d-flex justify-content-end">
                  {filteredData &&
                    filteredData?.map((item: any) => {
                      return (
                        <>
                          <div className="p-1">
                            <img src={item.logo} alt="" height={16} />
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
            <ReactECharts
              option={systemLineGraph}
              style={{ height: "400px", width: isMobile ? "100%" : "100%" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Cards;
