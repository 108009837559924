import { Images } from "./Config/Images";
import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";
import {
  getTopDestinationAnalytics,
  getTransactionAndUserAnalytics,
} from "../redux/apis/apisCrud";
const GraphCard = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [destinationAnalyticsData, setDestinationAnalyticsData] = useState<any>(
    []
  );
  const [userGraphData, setUserGraphData] = useState<any>([]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const getDestinationAnalytics = () => {
    getTopDestinationAnalytics()
      .then((response) => {
        setDestinationAnalyticsData(response?.data?.body?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getUserData = () => {
    getTransactionAndUserAnalytics()
      .then((response) => {
        console.log(response?.data?.body?.data, "resposneUser");
        setUserGraphData(response?.data?.body?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const reducedData =
    destinationAnalyticsData &&
    destinationAnalyticsData?.reduce((acc: any, curr: any) => {
      const existingNetwork = acc.find(
        (item: any) => item.network.name === curr.network.name
      );
      if (existingNetwork) {
        existingNetwork.volume.destinationAmount = (
          parseFloat(existingNetwork.volume.destinationAmount) +
          parseFloat(curr.volume.destinationAmount)
        ).toString();
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
  function formatDate(dateTimeString: any) {
    const date = new Date(dateTimeString);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  }
  const systemLineGraph = {
    tooltip: {
      trigger: "axis",
    },

    yAxis: {
      type: "value",
      axisLine: {
        show: false, // Hide the axis line
      },
      axisLabel: {
        show: false, // Hide the axis labels
      },
      splitLine: {
        show: true, // Hide the split line
        lineStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 1,
                color: "#1A1D1F", // Start color
              },
              {
                offset: 0,
                color: "rgba(86, 93, 99, 0)", // End color
              },
            ],
          },
        },
      },
    },
    xAxis: {
      // Add an empty xAxis configuration
      axisLine: {
        show: false, // Hide the axis line
      },
      axisLabel: {
        color: "#565d63", // Change the text color
        fontSize: 10,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 1,
                color: "#1A1D1F", // Start color
              },
              {
                offset: 0,
                color: "rgba(86, 93, 99, 0)", // End color
              },
            ],
          },
        },
      },

      type: "category",
      data:
        userGraphData &&
        userGraphData?.newUsersAnalytics?.map((item: any) =>
          formatDate(item.date)
        ), // You can leave it empty if you don't want specific x-axis labels
    },
    series: [
      {
        name: "Info",
        type: "line",
        symbol: "circle",
        smooth: true,
        symbolSize: 1,
        data:
          userGraphData &&
          userGraphData?.newUsersAnalytics?.map((item: any) => item.newUsers),
        itemStyle: {
          color: "#D9B373", // Change color as needed
        },
      },
    ],
  };
  console.log(destinationAnalyticsData, "destinationAnalyticsData");
  const systemBarGraph = {
    tooltip: {
      trigger: "axis",
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: false, // Hide the axis line
      },
      axisLabel: {
        show: false, // Hide the axis labels
      },
      splitLine: {
        show: false, // Hide the split line
      },
    },

    xAxis: {
      // Add an empty xAxis configuration
      axisLine: {
        show: false, // Hide the axis line
      },
      axisLabel: {
        color: "#565d63", // Change the text color
      },
      splitLine: {
        show: false, // Hide the split line
      },
      data:
        reducedData &&
        reducedData?.map((item: any) => item.network?.name?.split(" ")[0]),
      type: "category",
      // You can leave it empty if you don't want specific x-axis labels
    },
    series: [
      {
        name: "Info",
        type: "bar", // Change type to 'bar'
        barMaxWidth: "30%", // Adjust bar width as needed
        data:
          reducedData &&
          reducedData?.map((item: any) => ({
            value: item.volume.destinationAmount,
            name: item.network.name?.split(" ")[0],
          })), // Adjust data for bar chart

        itemStyle: {
          // Apply linear gradient color to the bars
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#b79f74", // Start color
              },
              {
                offset: 1,
                color: "#1A1D1F", // End color
              },
            ],
          },
        },
      },
    ],
  };
  useEffect(() => {
    getDestinationAnalytics();
    getUserData();
  }, []);
  const calculateNewUsers = () => {
    let totalNewUsers = 0;
    userGraphData &&
      userGraphData?.newUsersAnalytics?.forEach((data: any) => {
        totalNewUsers += data.newUsers;
      });
    return totalNewUsers;
  };
  return (
    <div className="col-12 mt-4 d-flex justify-content-center">
      <div className="col-md-11 col-12 mobile-responsive">
        <div className="col-md-6 col-12 p-1 p-3">
          <div className="graph-card img-fluid">
            <div className="p-4 d-flex">
              <div className="col-md-6 col-12">
                <div className="d-flex align-items-center">
                  <div className="p-1">
                    <img src={Images.userLogo} alt="" height={25} width={37} />
                  </div>
                  <div
                    style={{
                      color: "#FFFFFF",
                      lineHeight: "30px",
                      fontSize: "25px",
                      fontWeight: "400",
                    }}
                    className="ps-4"
                  >
                    Unique Users
                  </div>
                </div>
                <div
                  style={{
                    color: "#FCFCFC",
                    lineHeight: "60px",
                    fontSize: "72px",
                    fontWeight: "400",
                    width: "100%",
                    overflowX: "auto",
                    overflowY: "hidden",
                  }}
                  className="mt-2 p-1"
                >
                  {userGraphData?.generalAnalytics?.uniqueUsers}
                </div>
                <div
                  style={{
                    color: "#D9B373",
                    lineHeight: "20px",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  className="mt-3 p-1"
                >
                  NEW USERS
                </div>
                <div
                  style={{
                    color: "#FCFCFC",
                    lineHeight: "48px",
                    fontSize: "40px",
                    fontWeight: "400",
                    width: "90%",
                    overflow: "auto",
                  }}
                  className="p-1"
                >
                  {calculateNewUsers()}
                </div>
              </div>
              {!isMobile && (
                <div className="col-6">
                  <ReactECharts
                    option={systemLineGraph}
                    style={{ height: "250px", width: "100%" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 p-1 p-3">
          <div className="graph-card img-fluid">
            <div className="p-4">
              <div className="col-12">
                <div className="d-flex align-items-center">
                  <div className="p-1">
                    <img
                      src={Images.destinationLogo}
                      alt=""
                      height={25}
                      width={37}
                    />
                  </div>
                  <div
                    style={{
                      color: "#FFFFFF",
                      lineHeight: "30px",
                      fontSize: "25px",
                      fontWeight: "400",
                    }}
                    className="ps-4"
                  >
                    Top Destinations
                  </div>
                </div>
                <div className="col-12">
                  <ReactECharts
                    option={systemBarGraph}
                    style={{ height: "210px", width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphCard;
