import { use } from "echarts/core";
import React, { useState } from "react";
import footerLogo from "../assets/images/footerLogo.png";

const Footer = () => {
  const [showSuperAdmin, setShowSuperAdmin] = useState(false);

  return (
    <div className="d-flex justify-content-center p-5">
      <img src={footerLogo} alt="" />
    </div>
  );
};
export default Footer;
