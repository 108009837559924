import React, { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { Images } from "./Config/Images";
import bsc from "../assets/images/bscImg.png";
import { getTopChainAnalytics } from "../redux/apis/apisCrud";
const ChainCharts = () => {
  const [getChainGraphData, setGetChainGraphData] = useState<any>("");
  const [getChainData, setGetChainData] = useState<any>("");
  const data = [
    {
      network: {
        _id: "63f77721956e88154f5b3ecb",
        name: "Arbitrum",
        nameInLower: "arbitrum",
        networkShortName: "arb",
        ferrumNetworkIdentifier: "42161",
        chainId: "42161",
        networkId: "42161",
        rpcUrl:
          "https://nd-499-825-018.p2pify.com/5d8bab30e1462f48144c36f18d2ee958",
        blockExplorerUrl: "https://arbiscan.io",
        networkCurrencySymbol: "ETH",
        dexInputCurrencySymbolList: ["ETH"],
        isTestnet: false,
        isActive: true,
        isAllowedOnGateway: true,
        isAllowedOnMultiSwap: true,
        logo: "https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg",
        publicRpcUrl: "publicRpcUrl",
        backupRpcUrl: "backupRpcUrl",
        positionForMultiSwap: 0,
        multiSwapFiberRouterSmartContractAddress:
          "0xb73A2a6cd7297844B1CbfcFfEb2884bEaf4C22B1",
        isNonEVM: false,
        createdAt: "2023-02-23T14:24:33.200Z",
        updatedAt: "2023-02-08T16:42:41.786Z",
        parentId: null,
        networkCurrencyAddressByNetwork: null,
        user: "6204f7be81113b1c740e5432",
        __v: 0,
        multiswapNetworkFIBERInformation: "64243254655a734d5b51dc9b",
        isAllowedDynamicGasValues: true,
        threshold: 5,
      },
      volume: 1634750.5655365,
    },
    {
      network: {
        isAllowedOnGateway: false,
        publicRpcUrl: "",
        backupRpcUrl: "",
        positionForMultiSwap: 0,
        isNonEVM: false,
        _id: "61f182eb6cc928420e002401",
        name: "Ethereum",
        nameInLower: "ethereum",
        networkShortName: "Ethereum",
        ferrumNetworkIdentifier: "1",
        chainId: "1",
        networkId: "1",
        rpcUrl:
          "https://nd-294-644-098.p2pify.com/a10c91aef3a8c2e65c40afd72050a82b",
        blockExplorerUrl: "https://etherscan.io",
        dexInputCurrencySymbolList: ["ETH"],
        isTestnet: false,
        isActive: true,
        createdAt: "2022-01-26T17:20:43.676Z",
        updatedAt: "2022-01-26T17:31:06.271Z",
        parentId: null,
        user: "61a6553288dd8363bb954c7a",
        __v: 0,
        networkCurrencySymbol: "ETH",
        isAllowedOnMultiSwap: true,
        logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
        multiswapNetworkFIBERInformation: "65d8603bf63126072df33e14",
        multiSwapFiberRouterSmartContractAddress:
          "0x7FfD92b850c5660FB40BD5Efa4f24eb22665E4c4",
        isAllowedDynamicGasValues: false,
        threshold: 5,
      },
      volume: 1781316,
    },
    {
      network: {
        publicRpcUrl: "",
        backupRpcUrl: "",
        positionForMultiSwap: 0,
        _id: "61f17e726cc928420e0023f7",
        name: "BSC",
        nameInLower: "bsc",
        networkShortName: "BSC",
        ferrumNetworkIdentifier: "56",
        chainId: "56",
        networkId: "56",
        rpcUrl: "https://bsc-dataseed.binance.org/",
        blockExplorerUrl: "https://bscscan.com/",
        dexInputCurrencySymbolList: ["BNB"],
        isTestnet: false,
        isActive: true,
        createdAt: "2022-01-26T17:01:38.447Z",
        updatedAt: "2022-03-20T20:39:46.439Z",
        parentId: null,
        user: "61a6553288dd8363bb954c7a",
        __v: 0,
        networkCurrencySymbol: "BNB",
        isAllowedOnGateway: true,
        networkCurrencyAddressByNetwork: "622f6c497a0cc65e0dee643c",
        contractAddress: "0x8e01cc26d6dd73581347c4370573ce9e59e74802",
        isAllowedOnMultiSwap: true,
        logo: "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/ic_bsc.png",
        multiSwapFiberRouterSmartContractAddress:
          "0x4B87Ab46B56990Aff03dAD1caFEb33e760879d97",
        multiswapNetworkFIBERInformation: "63c938d4fc4a2805e65e1af5",
        isNonEVM: false,
        isAllowedDynamicGasValues: true,
        threshold: 5,
      },
      volume: 7589543,
    },
  ];
  const dataline = {
    graphData: {
      "2024-05-08": [
        {
          volume: {
            settledAmount: "1187544",
          },
          network: {
            isAllowedOnGateway: false,
            publicRpcUrl: "",
            backupRpcUrl: "",
            positionForMultiSwap: 0,
            isNonEVM: false,
            _id: "61f182eb6cc928420e002401",
            name: "sample",
            nameInLower: "ethereum",
            networkShortName: "Ethereum",
            ferrumNetworkIdentifier: "1",
            chainId: "1",
            networkId: "1",
            rpcUrl:
              "https://nd-294-644-098.p2pify.com/a10c91aef3a8c2e65c40afd72050a82b",
            blockExplorerUrl: "https://etherscan.io",
            dexInputCurrencySymbolList: ["ETH"],
            isTestnet: false,
            isActive: true,
            createdAt: "2022-01-26T17:20:43.676Z",
            updatedAt: "2022-01-26T17:31:06.271Z",
            parentId: null,
            user: "61a6553288dd8363bb954c7a",
            __v: 0,
            networkCurrencySymbol: "ETH",
            isAllowedOnMultiSwap: true,
            logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
            multiswapNetworkFIBERInformation: "65d8603bf63126072df33e14",
            multiSwapFiberRouterSmartContractAddress:
              "0x7FfD92b850c5660FB40BD5Efa4f24eb22665E4c4",
            isAllowedDynamicGasValues: false,
            threshold: 5,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            settledAmount: "1089833856.3770244",
          },
          network: {
            _id: "63f77721956e88154f5b3ecb",
            name: "sample1",
            nameInLower: "arbitrum",
            networkShortName: "arb",
            ferrumNetworkIdentifier: "42161",
            chainId: "42161",
            networkId: "42161",
            rpcUrl:
              "https://nd-499-825-018.p2pify.com/5d8bab30e1462f48144c36f18d2ee958",
            blockExplorerUrl: "https://arbiscan.io",
            networkCurrencySymbol: "ETH",
            dexInputCurrencySymbolList: ["ETH"],
            isTestnet: false,
            isActive: true,
            isAllowedOnGateway: true,
            isAllowedOnMultiSwap: true,
            logo: "https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg",
            publicRpcUrl: "publicRpcUrl",
            backupRpcUrl: "backupRpcUrl",
            positionForMultiSwap: 0,
            multiSwapFiberRouterSmartContractAddress:
              "0xb73A2a6cd7297844B1CbfcFfEb2884bEaf4C22B1",
            isNonEVM: false,
            createdAt: "2023-02-23T14:24:33.200Z",
            updatedAt: "2023-02-08T16:42:41.786Z",
            parentId: null,
            networkCurrencyAddressByNetwork: null,
            user: "6204f7be81113b1c740e5432",
            __v: 0,
            multiswapNetworkFIBERInformation: "64243254655a734d5b51dc9b",
            isAllowedDynamicGasValues: true,
            threshold: 5,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            settledAmount: "0",
          },
          network: {
            publicRpcUrl: "",
            backupRpcUrl: "",
            positionForMultiSwap: 0,
            _id: "61f17e726cc928420e0023f7",
            name: "sample2",
            nameInLower: "bsc",
            networkShortName: "BSC",
            ferrumNetworkIdentifier: "56",
            chainId: "56",
            networkId: "56",
            rpcUrl: "https://bsc-dataseed.binance.org/",
            blockExplorerUrl: "https://bscscan.com/",
            dexInputCurrencySymbolList: ["BNB"],
            isTestnet: false,
            isActive: true,
            createdAt: "2022-01-26T17:01:38.447Z",
            updatedAt: "2022-03-20T20:39:46.439Z",
            parentId: null,
            user: "61a6553288dd8363bb954c7a",
            __v: 0,
            networkCurrencySymbol: "BNB",
            isAllowedOnGateway: true,
            networkCurrencyAddressByNetwork: "622f6c497a0cc65e0dee643c",
            contractAddress: "0x8e01cc26d6dd73581347c4370573ce9e59e74802",
            isAllowedOnMultiSwap: true,
            logo: "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/ic_bsc.png",
            multiSwapFiberRouterSmartContractAddress:
              "0x4B87Ab46B56990Aff03dAD1caFEb33e760879d97",
            multiswapNetworkFIBERInformation: "63c938d4fc4a2805e65e1af5",
            isNonEVM: false,
            isAllowedDynamicGasValues: true,
            threshold: 5,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
      ],
    },
  };
  const colorArray = [
    "gold",
    "peach",
    "coral",
    "lavender",
    "lime",
    "turquoise",
    "magenta",
    "pink",
    "purple",
    "blue",
    "cyan",
    "green",
    "yellow",
    "orange",
    "red",
  ];
  const getChainDataApi = () => {
    getTopChainAnalytics()
      .then((response) => {
        console.log(response, "tokenresponse");
        setGetChainData(response?.data?.body?.data?.mainEntries);
        setGetChainGraphData(response?.data?.body?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getChainDataApi();
  }, []);
  function convertToUpperFormat(graphData: any) {
    const upperFormat: any = [];
    console.log(graphData, "graphData12345678");
    // Iterate through each date in graphData
    for (const date in graphData) {
      if (graphData.hasOwnProperty(date)) {
        // Iterate through each entry for the current date
        graphData[date].forEach((entry: any) => {
          const amount = entry.volume.settledAmount;
          const name = entry.network.name;

          // Push an object with date, amount, and name into upperFormat array
          upperFormat.push({
            date: date,
            amount: amount,
            name: name,
          });
        });
      }
    }

    return upperFormat;
  }

  const upperFormatData = convertToUpperFormat(getChainGraphData?.graphData);
  console.log(upperFormatData, getChainGraphData, "upperFormatData");
  const { graphData } = getChainGraphData ? getChainGraphData : dataline;
  const firstObjectValue = Object.values(dataline.graphData)[0];
  const graphDataArray = Object.entries(graphData).map(([date, values]) => {
    return { date, values };
  });
  const graphDataArray2 = Object.entries(graphData).map(([date]) => {
    return date;
  });
  const uniqueDates = Array.from(
    new Set(upperFormatData.map((item: any) => item.date))
  );
  const filteredData = upperFormatData.reduce((acc: any, curr: any) => {
    const existingEntry = acc.find((item: any) => item.name === curr.name);
    if (existingEntry) {
      existingEntry.amounts.push(curr.amount);
    } else {
      acc.push({ name: curr.name, amounts: [curr.amount] });
    }
    return acc;
  }, []);
  console.log(
    // graphDataArray,
    // firstObjectValue,
    // "graphDataArray",
    graphDataArray2
  );

  graphDataArray2.map((item) => {
    console.log(
      "---->>>>",
      graphDataArray.filter((data) => data.date != item)
    );
    // graphDataArray.filter((data) => data.date != item).keys;
  });
  const [legends, setLegends] = useState<any>();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Ref to hold echarts instance
  const chartRef: any = useRef(null);
  const data1 = "ETHERIUM";
  const data2 = "ARBITRUM";
  const data3 = "AVALANCHE";
  const data4 = "SOL";
  const seriesData = [
    {
      symbolSize: 1,
      data:
        getChainData &&
        getChainData.map((item: any) => ({
          value: item.volume,
          name: item.network.name,
        })),
      name: getChainData && getChainData.map((item: any) => item.network.name),
      type: "pie",
      radius: ["80%"],
      center: isMobile ? ["50%", "50%"] : ["50%", "50%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
    },
  ];
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let colors: any = "";
  // Function to handle finished event
  const handleChartFinish = () => {
    // Access echarts instance
    const instance = chartRef.current.getEchartsInstance();
    // Get colors used in the chart
    colors = instance.getOption().color;
    // Log colors to the console
    console.log("Colors:", colors);
    if (!legends) {
      setLegends(colors);
    }
  };

  // Effect to subscribe to finished event when component mounts
  useEffect(() => {
    // Cleanup function
    return () => {
      // Unsubscribe from finished event
      if (chartRef.current) {
        chartRef.current
          .getEchartsInstance()
          .off("finished", handleChartFinish);
      }
    };
  }, []);

  const systemPieGraph = {
    tooltip: {
      trigger: "item",
    },
    // legend: {
    //   orient: "vertical",
    //   right: 150,
    //   top: "200",
    //   textStyle: {
    //     // Add textStyle property here
    //     color: "white", // Set the color you want for the legend text
    //   },
    //   itemGap: 10, // Set gap between legend items
    //   itemWidth: 10, // Set width of legend symbol
    //   itemHeight: 20,
    // },
    graphic: [
      {
        type: "text",
        left: isMobile ? "10" : "80",
        top: 40,

        z: 100, // Ensure the label is above other chart elements
        style: {
          fill: "#FFFFFF", // Color of the label text
          text: "Top Chains", // Text content of the label
          fontSize: isMobile ? 20 : 30, // Font size of the label
          fontWeight: "400", // Font weight of the label
          fontFamily: "FRM-Aber Mono",
        },
      },
    ],
    series: seriesData,
  };
  const seriesDataLine = filteredData.map((item: any, index: any) => ({
    name: item.name,
    type: "line",
    stack: "total",
    symbol: "circle",
    symbolSize: 1,
    data: item.amounts.map((item: any) => item),
    areaStyle: true,
  }));
  const systemLineGraph = {
    tooltip: {
      trigger: "axis",
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: true, // Hide the split line
        lineStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 1,
                color: "#1A1D1F", // Start color
              },
              {
                offset: 0,
                color: "rgba(86, 93, 99, 0)", // End color
              },
            ],
          },
        },
      },
    },
    xAxis: {
      type: "category",
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true, // Hide the split line
        lineStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 1,
                color: "#1A1D1F", // Start color
              },
              {
                offset: 0,
                color: "rgba(86, 93, 99, 0)", // End color
              },
            ],
          },
        },
      },
      data: uniqueDates,
    },
    series: seriesDataLine,
  };
  const images = [bsc, Images.ava, Images.ethImg, Images.avalanche];
  console.log(legends, "legends");
  return (
    <div className="w-100 mt-4 d-flex justify-content-center">
      <div className="col-md-11 col-12 mobile-responsive">
        <div className="col-md-6 col-12 d-flex align-items-center">
          {" "}
          <div className="col-6 pt-5">
            <ReactECharts
              ref={chartRef}
              echarts={echarts}
              option={systemPieGraph}
              style={{ height: "500px", width: "100%" }}
              onEvents={{
                finished: handleChartFinish,
              }}
            />
          </div>
          <div className="col-6">
            {getChainData &&
              getChainData?.map((item: any, index: any) => {
                return (
                  <>
                    <div className="p-1 d-flex" style={{ color: "white" }}>
                      <div
                        className="col-1"
                        style={{
                          height: "20px",
                          width: "10px",
                          backgroundColor: legends && legends[index],
                        }}
                      ></div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <img src={item.network.logo} alt="" height={20} />
                      </div>
                      <div
                        className="col-5 d-flex align-items-center"
                        style={isMobile ? { fontSize: "10px" } : {}}
                      >
                        {item.network.name}
                      </div>
                      <div
                        className="col-4 d-flex align-items-center"
                        style={
                          isMobile
                            ? {
                                fontSize: "10px",
                                color: "gray",
                                overflow: "auto",
                                scrollbarWidth: "none",
                              }
                            : {
                                color: "gray",
                                overflow: "auto",
                                scrollbarWidth: "none",
                              }
                        }
                      >
                        {Number(item.volume).toFixed(2)}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <div className="col-md-6 col-12">
          {" "}
          <ReactECharts
            ref={chartRef}
            option={systemLineGraph}
            style={{ height: "500px", width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ChainCharts;
