import React, { useEffect, useState } from "react";
import { Images } from "../Config/Images";
import { useLocation, useNavigate } from "react-router";
import WalletConnect from "@walletconnect/client";

const MS_URL = process.env.REACT_APP_MS_URL;
const DasbhboardHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showSuperAdmin, setShowSuperAdmin] = useState(false);
  const [activeButton, setActiveButton] = useState<any>(false);
  const [wcConnector, setWcConnector] = useState<WalletConnect | null>(null);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [connected, setConnected] = useState(false);
  const pathname = location.pathname;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const style = {
    color: pathname === "/projects/detail" ? "white" : "darkgray",
    fontSize: "20px", // Adjust the font size as needed
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleUrlHome = () => {
    navigate(`/`);
  };
  const onclose = () => {
    setShow(false);
  };
  // const RedirectToWebsite = () => {
  //   window.open(`https://www.blocjerk.com`, "_blank");
  // };
  return (
    <>
      <div className="d-flex p-2 col-12 align-items-center">
        <div className="col-md-3 col-6 p-2">
          <a href={`${MS_URL}/swap`}>
            <img
              className="ps-1"
              src={Images.headerLogo}
              alt=""
              height={40}
              width={71}
            />
          </a>
        </div>
        {!isMobile && (
          <div className="col-6 d-flex justify-content-center">
            <div className="d-flex">
              <div
                className={
                  !activeButton
                    ? "header-button d-flex align-items-center cursor-pointer"
                    : "header-button ms-3 d-flex align-items-center cursor-pointer"
                }
                onClick={() => {
                  setActiveButton(false);
                }}
              >
                <div className="pr-1">
                  <a href="/">
                    <img src={Images.headerLogoAnalytics} alt="" />
                  </a>
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <div className="ps-2">Analytics</div>
                </div>
              </div>
              <div
                className={
                  activeButton
                    ? "header-button-transaction ms-3 d-flex align-items-center cursor-pointer"
                    : "header-button-transaction ms-3 d-flex align-items-center cursor-pointer"
                }
                onClick={() => {
                  window.open(`${MS_URL}/transactions`);
                  setActiveButton(!activeButton);
                }}
              >
                <div className="pr-1">
                  <img src={Images.transactionLogo} alt="" />
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <div className="ps-2">Transaction</div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="col-md-3 col-6 d-flex justify-content-end">
          {" "}
          <div className="header-button-multiswap pr-2  d-flex align-items-center cursor-pointer">
            <div className="pr-1">
              <img src={Images.multiswapButtonLogo} alt="" />
            </div>
            <div
              style={{
                color: "black",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <div
                className="ps-2"
                onClick={() => window.open(`${MS_URL}/swap`)}
              >
                Go to Multiswap
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center">
        {isMobile && (
          <div className="col-9 d-flex justify-content-center">
            <div className="d-flex">
              <div
                className={
                  !activeButton
                    ? "header-button d-flex align-items-center cursor-pointer"
                    : "header-button ms-3 d-flex align-items-center cursor-pointer"
                }
                onClick={() => {
                  setActiveButton(false);
                }}
              >
                <div className="pr-1">
                  <img src={Images.headerLogoAnalytics} alt="" />
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <div className="ps-2">Analytics</div>
                </div>
              </div>
              <div
                className={
                  activeButton
                    ? "header-button-transaction ms-3 d-flex align-items-center cursor-pointer"
                    : "header-button-transaction ms-3 d-flex align-items-center cursor-pointer"
                }
                onClick={() => {
                  window.open(`${MS_URL}/transactions`);
                  setActiveButton(!activeButton);
                }}
              >
                <div className="pr-1">
                  <img src={Images.transactionLogo} alt="" />
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <div className="ps-2">Transactions</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default DasbhboardHeader;
