import axios from "axios";

export const BASE_URL = process.env.REACT_APP_MS_ANALYTICS_URL;
// export const BASE_URL = "http://192.168.1.5:8090";
export function getTopDestinationAnalytics() {
  return axios.get(`${BASE_URL}/v1/analytics/getTopDestinationAnalytics`);
}
export function getTopChainAnalytics() {
  return axios.get(`${BASE_URL}/v1/analytics/getTopChainAnalytics`);
}
export function getTopTokenAnalytics() {
  return axios.get(`${BASE_URL}/v1/analytics/getTopTokenAnalytics`);
}
export function getTransactionAndUserAnalytics() {
  return axios.get(`${BASE_URL}/v1/analytics/getTransactionAndUserAnalytics`);
}
