import React, { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { Images } from "./Config/Images";
import bsc from "../assets/images/bscImg.png";
import { getTopTokenAnalytics } from "../redux/apis/apisCrud";
const TokenCharts = () => {
  const [getTokenGraphData, setGetTokenGraphData] = useState<any>("");
  const [getTokenData, setGetTokenData] = useState<any>("");
  ///2024-05-08

  const dataline = {
    graphData: {
      "2000-00-00": [
        {
          volume: {
            totalAmount: "0",
          },
          currency: {
            isCreatedFromBulk: false,
            _id: "64249b6a9ab696463b4b86af",
            currencyAddressesByNetwork: [
              "64249b6a9ab696463b4b86b1",
              "64c77359ceffaf0ca49c9f42",
              "65d8e41fe4deb16a62c378a3",
            ],
            name: "--",
            nameInLower: "tether usd",
            symbol: "USDT",
            logo: "https://arbiscan.io/token/images/tether_32.png",
            totalSupply: 449045610.443502,
            isActive: true,
            isVisibleForPublicMenuItem: true,
            valueInUsd: 0,
            usdValueConversionPath: [],
            createdAt: "2023-03-29T20:11:22.446Z",
            updatedAt: "2023-03-29T20:11:22.446Z",
            createdByUser: "6204f7be81113b1c740e5432",
            updatedByUser: "6204f7be81113b1c740e5432",
            createdByOrganization: "62613615bffc2260cc852836",
            __v: 0,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "340173579.87246525",
          },
          currency: {
            isCreatedFromBulk: false,
            _id: "64249bb79ab696463b4b86d7",
            currencyAddressesByNetwork: [
              "64249bb79ab696463b4b86d9",
              "65d8e80ae4deb16a62c37d00",
            ],
            name: "Spell",
            nameInLower: "spell",
            symbol: "SPELL",
            logo: "https://arbiscan.io/token/images/spelltoken_32.png",
            totalSupply: 1466421288.997134,
            isActive: true,
            isVisibleForPublicMenuItem: true,
            valueInUsd: 0,
            usdValueConversionPath: [],
            createdAt: "2023-03-29T20:12:39.109Z",
            updatedAt: "2023-03-29T20:12:39.109Z",
            createdByUser: "6204f7be81113b1c740e5432",
            updatedByUser: "6204f7be81113b1c740e5432",
            createdByOrganization: "62613615bffc2260cc852836",
            __v: 0,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "308002695.9279253",
          },
          currency: {
            isCreatedFromBulk: false,
            _id: "63d3accd56e6cf3de3133788",
            currencyAddressesByNetwork: ["63d3accd56e6cf3de313378a"],
            name: "bloXmove",
            nameInLower: "bloxmove",
            symbol: "BLXM",
            logo: "https://bscscan.com/token/images/bloxmovetoken_32.png",
            totalSupply: 1.0999702543e22,
            isActive: true,
            isVisibleForPublicMenuItem: true,
            valueInUsd: 0,
            usdValueConversionPath: [],
            createdAt: "2023-01-27T10:51:57.676Z",
            updatedAt: "2023-01-27T10:51:57.676Z",
            createdByUser: "6204f7be81113b1c740e5432",
            updatedByUser: "6204f7be81113b1c740e5432",
            createdByOrganization: "62613615bffc2260cc852836",
            __v: 0,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "220000.22",
          },
          currency: {
            isCreatedFromBulk: false,
            _id: "64249cca9ab696463b4b8700",
            currencyAddressesByNetwork: ["64249cca9ab696463b4b8702"],
            name: "Magic Internet Money",
            nameInLower: "magic internet money",
            symbol: "MIM",
            logo: "https://arbiscan.io/token/images/mimstablecoin_32.png",
            totalSupply: 31458747.853164,
            isActive: true,
            isVisibleForPublicMenuItem: true,
            valueInUsd: 0,
            usdValueConversionPath: [],
            createdAt: "2023-03-29T20:17:14.687Z",
            updatedAt: "2023-03-29T20:17:14.687Z",
            createdByUser: "6204f7be81113b1c740e5432",
            updatedByUser: "6204f7be81113b1c740e5432",
            createdByOrganization: "62613615bffc2260cc852836",
            __v: 0,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "200000.2",
          },
          currency: {
            isCreatedFromBulk: false,
            _id: "6422b3eab8ecef3c0bb14de5",
            currencyAddressesByNetwork: ["6422b3eab8ecef3c0bb14de7"],
            name: "Binance-Peg Cardano Token",
            nameInLower: "binance-peg cardano token",
            symbol: "ADA",
            logo: "https://bscscan.com/token/images/cardano_32.png",
            totalSupply: 280000000,
            isActive: true,
            isVisibleForPublicMenuItem: true,
            valueInUsd: 0,
            usdValueConversionPath: [],
            createdAt: "2023-03-28T09:31:22.637Z",
            updatedAt: "2023-03-28T09:31:22.637Z",
            createdByUser: "6204f7be81113b1c740e5432",
            updatedByUser: "6204f7be81113b1c740e5432",
            createdByOrganization: "62613615bffc2260cc852836",
            __v: 0,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "18143075.969745338",
          },
          currency: {
            isCreatedFromBulk: false,
            _id: "64249a5d9ab696463b4b7f6f",
            currencyAddressesByNetwork: ["64249a5d9ab696463b4b7f71"],
            name: "SushiToken",
            nameInLower: "sushitoken",
            symbol: "SUSHI",
            logo: "https://arbiscan.io/token/images/sushitoken_32.png",
            totalSupply: 465180.155863,
            isActive: true,
            isVisibleForPublicMenuItem: true,
            valueInUsd: 0,
            usdValueConversionPath: [],
            createdAt: "2023-03-29T20:06:53.468Z",
            updatedAt: "2023-03-29T20:06:53.468Z",
            createdByUser: "6204f7be81113b1c740e5432",
            updatedByUser: "6204f7be81113b1c740e5432",
            createdByOrganization: "62613615bffc2260cc852836",
            __v: 0,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "17508807.521095563",
          },
          currency: {
            isCreatedFromBulk: false,
            _id: "6422b4ddb8ecef3c0bb14e01",
            currencyAddressesByNetwork: ["6422b4ddb8ecef3c0bb14e03"],
            name: "Binance-Peg Band Protocol Token",
            nameInLower: "binance-peg band protocol token",
            symbol: "BAND  ",
            logo: "https://bscscan.com/token/images/bandtoken_32.png",
            totalSupply: 2300000,
            isActive: true,
            isVisibleForPublicMenuItem: true,
            valueInUsd: 0,
            usdValueConversionPath: [],
            createdAt: "2023-03-28T09:35:25.291Z",
            updatedAt: "2023-03-28T09:35:25.291Z",
            createdByUser: "6204f7be81113b1c740e5432",
            updatedByUser: "6204f7be81113b1c740e5432",
            createdByOrganization: "62613615bffc2260cc852836",
            __v: 0,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "165342.1629445372",
          },
          currency: {
            valueInUsd: 0,
            usdValueConversionPath: [],
            isCreatedFromBulk: false,
            _id: "622bacec4c7c31311eb2b828",
            currencyAddressesByNetwork: [
              "622bacec4c7c31311eb2b82a",
              "63d3ab9456e6cf3de313373c",
              "659594ee98acfbf7dd34c221",
            ],
            name: "ETH",
            nameInLower: "eth",
            symbol: "ETH",
            logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
            totalSupply: 119918902,
            isActive: true,
            createdAt: "2022-03-11T20:11:24.576Z",
            updatedAt: "2022-03-10T12:30:44.167Z",
            createdByUser: "622b2d0c4c7c31311eb2b741",
            createdByOrganization: "622b2d0c4c7c31311eb2b743",
            __v: 0,
            isVisibleForPublicMenuItem: true,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "150176.14787758168",
          },
          currency: {
            valueInUsd: 0,
            usdValueConversionPath: [],
            isCreatedFromBulk: false,
            _id: "622f6c497a0cc65e0dee643a",
            currencyAddressesByNetwork: ["622f6c497a0cc65e0dee643c"],
            name: "BNB",
            nameInLower: "bnb",
            symbol: "BNB",
            logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
            totalSupply: 10000000000,
            isActive: true,
            createdAt: "2022-03-14T16:24:41.406Z",
            updatedAt: "2022-03-14T11:17:32.518Z",
            createdByUser: "622b2d0c4c7c31311eb2b741",
            createdByOrganization: "622b2d0c4c7c31311eb2b743",
            __v: 0,
            isVisibleForPublicMenuItem: true,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "1202397.2066885387",
          },
          currency: {
            isCreatedFromBulk: false,
            _id: "6422b212b8ecef3c0bb14dc3",
            currencyAddressesByNetwork: ["6422b212b8ecef3c0bb14dc5"],
            name: "PancakeSwap Token",
            nameInLower: "pancakeswap token",
            symbol: "Cake",
            logo: "https://bscscan.com/token/images/pancake_32.png?=v1",
            totalSupply: 1149811459.751051,
            isActive: true,
            isVisibleForPublicMenuItem: true,
            valueInUsd: 0,
            usdValueConversionPath: [],
            createdAt: "2023-03-28T09:23:30.193Z",
            updatedAt: "2023-03-28T09:23:30.193Z",
            createdByUser: "6204f7be81113b1c740e5432",
            updatedByUser: "6204f7be81113b1c740e5432",
            createdByOrganization: "62613615bffc2260cc852836",
            __v: 0,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "111322023.1241478",
          },
          currency: {
            isCreatedFromBulk: false,
            _id: "6424aec29ab696463b4b9e91",
            currencyAddressesByNetwork: [
              "6424aec29ab696463b4b9e93",
              "64c77d7cceffaf0ca49ca0df",
            ],
            name: " USD Coin",
            nameInLower: " usd coin",
            symbol: "USDC ",
            logo: "https://arbiscan.io/token/images/centre-usdc_28.png",
            totalSupply: 1183718046.278644,
            isActive: true,
            isVisibleForPublicMenuItem: true,
            valueInUsd: 0,
            usdValueConversionPath: [],
            createdAt: "2023-03-29T21:33:54.528Z",
            updatedAt: "2023-03-29T21:33:54.528Z",
            createdByUser: "6204f7be81113b1c740e5432",
            updatedByUser: "6204f7be81113b1c740e5432",
            createdByOrganization: "62613615bffc2260cc852836",
            __v: 0,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "0",
          },
          currency: {
            isCreatedFromBulk: false,
            _id: "639ddddbdfe9d6d8ed5ec30e",
            currencyAddressesByNetwork: [
              "639ddddcdfe9d6d8ed5ec310",
              "639dde24dfe9d6d8ed5ec32a",
              "63eb7d75956e88154f5aec02",
              "63eba4ad956e88154f5af481",
              "64a6bf9e2ec8bc7dde06ea75",
              "64e64fd881534c2b3592e7e0",
              "65d8dabae4deb16a62c37151",
            ],
            name: "USDC",
            nameInLower: "usdc",
            symbol: "USDC",
            logo: "https://bscscan.com/token/images/usdcgno_32.png",
            totalSupply: 3003048.548054,
            isActive: true,
            isVisibleForPublicMenuItem: true,
            valueInUsd: 0,
            usdValueConversionPath: [],
            createdAt: "2022-12-17T15:18:51.721Z",
            updatedAt: "2022-12-17T15:18:51.721Z",
            createdByUser: "6204f7be81113b1c740e5432",
            updatedByUser: "6204f7be81113b1c740e5432",
            createdByOrganization: "62613615bffc2260cc852836",
            __v: 0,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
        {
          volume: {
            totalAmount: "0",
          },
          currency: {
            isCreatedFromBulk: false,
            _id: "6422381ab8ecef3c0bb14663",
            currencyAddressesByNetwork: [
              "6422381ab8ecef3c0bb14665",
              "64223994b8ecef3c0bb1467e",
              "64223acdb8ecef3c0bb1469f",
              "64223b89b8ecef3c0bb146b9",
              "64223d3eb8ecef3c0bb146d9",
            ],
            name: "Ferrum Network Token",
            nameInLower: "ferrum network token",
            symbol: "FRM",
            logo: "https://etherscan.io/token/images/ferrumnetwork_32.png?=v454",
            totalSupply: 611527268.4110012,
            isActive: true,
            isVisibleForPublicMenuItem: true,
            valueInUsd: 0,
            usdValueConversionPath: [],
            createdAt: "2023-03-28T00:43:06.928Z",
            updatedAt: "2023-03-28T00:43:06.928Z",
            createdByUser: "6204f7be81113b1c740e5432",
            updatedByUser: "6204f7be81113b1c740e5432",
            createdByOrganization: "62613615bffc2260cc852836",
            __v: 0,
          },
          date: "2024-05-08T00:00:00.000Z",
        },
      ],
    },
  };
  const getTokenDataApi = () => {
    getTopTokenAnalytics()
      .then((response) => {
        console.log(response, "tokenresponse");
        setGetTokenData(response?.data?.body?.data?.mainEntries);
        setGetTokenGraphData(response?.data?.body?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function convertToUpperFormat(graphData: any) {
    const upperFormat: any = [];

    // Iterate through each date in graphData
    for (const date in graphData) {
      if (graphData.hasOwnProperty(date)) {
        // Iterate through each entry for the current date
        graphData[date].forEach((entry: any) => {
          const amount = entry.volume.totalAmount;
          const name = entry.currency.name;

          // Push an object with date, amount, and name into upperFormat array
          upperFormat.push({
            date: date,
            amount: amount,
            name: name,
          });
        });
      }
    }

    return upperFormat;
  }
  const upperFormatData = convertToUpperFormat(
    getTokenGraphData?.graphData
      ? getTokenGraphData?.graphData
      : dataline.graphData
  );
  console.log(upperFormatData, "upperFormatData");
  const { graphData } = getTokenGraphData ? getTokenGraphData : dataline;
  const firstObjectValue = getTokenGraphData.graphData
    ? Object.values(getTokenGraphData.graphData)[0]
    : Object.values(dataline.graphData)[0];
  const graphDataArray = Object.entries(graphData).map(([date, values]) => {
    return { date, values };
  });
  const graphDataArray2 = Object.entries(graphData).map(([date]) => {
    return date;
  });
  const uniqueDates = Array.from(
    new Set(upperFormatData.map((item: any) => item.date))
  );
  const filteredData = upperFormatData.reduce((acc: any, curr: any) => {
    const existingEntry = acc.find((item: any) => item.name === curr.name);
    if (existingEntry) {
      existingEntry.amounts.push(curr.amount);
    } else {
      acc.push({ name: curr.name, amounts: [curr.amount] });
    }
    return acc;
  }, []);
  console.log(
    // graphDataArray,
    // firstObjectValue,
    // "graphDataArray",
    graphDataArray2
  );

  graphDataArray2.map((item) => {
    console.log(
      "---->>>>",
      graphDataArray.filter((data) => data.date != item)
    );
    // graphDataArray.filter((data) => data.date != item).keys;
  });
  const [legends, setLegends] = useState<any>();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    getTokenDataApi();
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const chartRef: any = useRef(null);

  const imagecheck = [
    {
      imag: " https://via.placeholder.com/150/FF0000/FFFFFF?Text=yttags.com",
    },
    {
      imag: " https://via.placeholder.com/150/FF0000/FFFFFF?Text=yttags.com",
    },
    {
      imag: " https://via.placeholder.com/150/FF0000/FFFFFF?Text=yttags.com",
    },
    {
      imag: " https://via.placeholder.com/150/FF0000/FFFFFF?Text=yttags.com",
    },
    {
      imag: " https://via.placeholder.com/150/FF0000/FFFFFF?Text=yttags.com",
    },
  ];
  let colors: any = "";
  const handleChartFinish = () => {
    // Access echarts instance
    const instance = chartRef.current.getEchartsInstance();
    // Get colors used in the chart
    colors = instance.getOption().color;
    // Log colors to the console
    console.log("Colors:", colors);
    if (!legends) {
      setLegends(colors);
    }
  };
  useEffect(() => {
    // Cleanup function
    return () => {
      // Unsubscribe from finished event
      if (chartRef.current) {
        chartRef.current
          .getEchartsInstance()
          .off("finished", handleChartFinish);
      }
    };
  }, []);
  const seriesData = [
    {
      symbolSize: 1,
      data:
        getTokenData &&
        getTokenData.map((item: any) => ({
          value: item.volume,
          name: item.currency.name,
        })),
      name: getTokenData && getTokenData.map((item: any) => item.currency.name),
      type: "pie",
      radius: ["80%"],
      center: isMobile ? ["50%", "50%"] : ["50%", "50%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
    },
  ];
  const systemPieGraph = {
    tooltip: {
      trigger: "item",
    },
    // legend: {
    //   orient: "vertical",
    //   right: 150,
    //   top: "200",
    //   textStyle: {
    //     // Add textStyle property here
    //     color: "white", // Set the color you want for the legend text
    //   },
    //   itemGap: 10, // Set gap between legend items
    //   itemWidth: 10, // Set width of legend symbol
    //   itemHeight: 20,
    // },
    graphic: [
      {
        type: "text",
        left: isMobile ? "10" : "80",
        top: 40,

        z: 100, // Ensure the label is above other chart elements
        style: {
          fill: "#FFFFFF", // Color of the label text
          text: "Top Tokens", // Text content of the label
          fontSize: isMobile ? 20 : 30, // Font size of the label
          fontWeight: "400", // Font weight of the label
          fontFamily: "FRM-Aber Mono",
        },
      },
    ],
    series: seriesData,
  };
  const seriesDataLine = filteredData.map((item: any, index: any) => ({
    name: item.name,
    type: "line",
    stack: "total",
    symbol: "circle",
    symbolSize: 1,
    data: item.amounts.map((item: any) => item),
    areaStyle: true,
  }));
  const systemLineGraph = {
    tooltip: {
      trigger: "axis",
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: true, // Hide the split line
        lineStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 1,
                color: "#1A1D1F", // Start color
              },
              {
                offset: 0,
                color: "rgba(86, 93, 99, 0)", // End color
              },
            ],
          },
        },
      },
    },
    xAxis: {
      type: "category",
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true, // Hide the split line
        lineStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 1,
                color: "#1A1D1F", // Start color
              },
              {
                offset: 0,
                color: "rgba(86, 93, 99, 0)", // End color
              },
            ],
          },
        },
      },
      data: uniqueDates,
    },
    series: seriesDataLine,
  };
  const images = [bsc, Images.ava, Images.ethImg, Images.avalanche];
  return (
    <div className="w-100 mt-4 d-flex justify-content-center">
      <div className="col-md-11 col-12 mobile-responsive">
        <div className="col-md-6 col-12 d-flex align-items-center">
          {" "}
          <div className="col-6 pt-5">
            <ReactECharts
              ref={chartRef}
              echarts={echarts}
              option={systemPieGraph}
              style={{ height: "500px", width: "100%" }}
              onEvents={{
                finished: handleChartFinish,
              }}
            />
          </div>
          <div className="col-6">
            {getTokenData &&
              getTokenData?.map((item: any, index: any) => {
                return (
                  <>
                    <div className="p-1 d-flex" style={{ color: "white" }}>
                      <div
                        className="col-1"
                        style={{
                          height: "20px",
                          width: "10px",
                          backgroundColor: legends && legends[index],
                        }}
                      ></div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <img src={item.currency.logo} alt="" height={20} />
                      </div>
                      <div
                        className="col-5 d-flex align-items-center"
                        style={isMobile ? { fontSize: "10px" } : {}}
                      >
                        {item.currency.symbol}
                      </div>
                      <div
                        className="col-4 d-flex align-items-center"
                        style={
                          isMobile
                            ? {
                                fontSize: "10px",
                                color: "gray",
                                overflow: "auto",
                                scrollbarWidth: "none",
                              }
                            : {
                                color: "gray",
                                overflow: "auto",
                                scrollbarWidth: "none",
                              }
                        }
                      >
                        {item.volume}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <div className="col-md-6 col-12">
          {" "}
          <ReactECharts
            ref={chartRef}
            option={systemLineGraph}
            style={{ height: "500px", width: "100%" }}
            onEvents={{
              finished: handleChartFinish,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TokenCharts;
