import React, { useEffect, useState } from "react";
import { getTransactionAndUserAnalytics } from "../redux/apis/apisCrud";
import { Images } from "./Config/Images";
const DashboardCards = () => {
  const [getData, setGetData] = useState<any>("");
  const generalAnalytics = {
    uniqueUsers: 0,
    assetsSwapped: 28,
    transactionTime: "28.3",
    totalVolume: 5264406321.6604185,
    totalTransactions: 8,
  };
  const formatNumber = (num: any) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(2) + "K";
    } else {
      return num.toString();
    }
  };
  const cardBlocks = [
    {
      title: "NUMBER OF TRANSACTIONS",
      detail: getData?.totalTransactions ? getData?.totalTransactions : "0",
      img: Images.firstCardLogo,
    },
    {
      title: "TOTAL VOLUME",
      detail: formatNumber(getData?.totalVolume ? getData?.totalVolume : "0"),
      img: Images.secondCardLogo,
    },
    {
      title: "TRANSACTION TIME",
      detail: `${
        getData?.transactionTime ? Math.floor(getData?.transactionTime) : "0"
      }`,
      img: Images.thirdCardLogo,
    },
    {
      title: "ASSETS SWAPED",
      detail: getData?.assetsSwapped ? getData?.assetsSwapped : "0",
      img: Images.fourthCardLogo,
    },
  ];
  const getCardData = () => {
    getTransactionAndUserAnalytics()
      .then((response) => {
        setGetData(response?.data?.body?.data?.generalAnalytics);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCardData();
  }, []);
  return (
    <div className="col-12 mt-4 d-flex justify-content-center">
      <div className="col-11 d-flex flex-wrap">
        {cardBlocks?.map((item: any) => {
          return (
            <>
              <div className="col-lg-4 col-xl-3 col-md-6 col-12 p-3">
                <div className="dashboard-card">
                  <div className="p-4">
                    <div className="pt-2">
                      <img src={item.img} alt="" height={30} width={30} />
                    </div>
                    <div
                      style={{
                        color: "#D9B373",
                        lineHeight: "20px",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                      className="mt-2"
                    >
                      {item.title}
                    </div>

                    <div
                      style={{
                        color: "#FCFCFC",
                        lineHeight: "1.2",
                        fontSize: "50px",
                        fontWeight: "400",

                        width: "100%",
                        overflow: "auto",
                        scrollbarWidth: "none",
                      }}
                      className="d-flex"
                    >
                      {item.detail}

                      {item.title == "TRANSACTION TIME" && (
                        <div
                          className="d-flex align-items-end pb-2"
                          style={{
                            color: "#FCFCFC",
                            fontSize: "30px",
                            fontWeight: "400",
                          }}
                        >
                          seconds
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardCards;
