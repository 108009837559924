import { useEffect, useLayoutEffect, useState } from "react";
import Cards from "./Cards";
import ChainCharts from "./ChainCharts";
import DashboardCards from "./DashboardCards";
import GraphCard from "./GraphCard";
import TokenCharts from "./TokenCharts";
const LanderPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="">
      <div style={isMobile ? {} : { paddingTop: "7rem" }}>
        <Cards />
      </div>
      <div style={{ paddingTop: "8rem" }}>
        <DashboardCards />
      </div>
      <div style={{ paddingTop: "4rem" }}>
        <TokenCharts />
      </div>
      <div style={{ paddingTop: "4rem" }}>
        <ChainCharts />
      </div>
      <div style={{ paddingTop: "4rem", paddingBottom: "5rem" }}>
        <GraphCard />
      </div>
    </div>
  );
};

export default LanderPage;
