import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { store, persistor } from "./redux/store";
import { Provider, useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";
import { PersistGate } from "redux-persist/integration/react";
import { router } from "./Routes/path";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider, useAccount, Connector } from "wagmi";
import { createWeb3Modal } from "@web3modal/wagmi/react";
const App = () => {
  return (
    <div>
      <Toaster containerClassName="col-12" reverseOrder={false} />
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
