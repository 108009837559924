import bscImg from "../../assets/images/bscImg.png"
import ethImg from "../../assets/images/ethImg.png"
import headerLogo from "../../assets/images/headerLogo.png"
import headerLogoAnalytics from "../../assets/images/buttonHeaderLogo.png"
import transactionLogo from "../../assets/images/transactionLogo.png"
import multiswapButtonLogo from "../../assets/images/multiswapButtonLogo.png"
import firstCardLogo from "../../assets/images/firstCardLogo.png"
import secondCardLogo from "../../assets/images/secondCardLogo.png"
import thirdCardLogo from "../../assets/images/thirdCardLogo.png"
import fourthCardLogo from "../../assets/images/FourthCardLogo.png"
import userLogo from "../../assets/images/userLogo.png"
import destinationLogo from "../../assets/images/destinationLogo.png"
import ava from "../../assets/images/Ava.png"
import avalanche from "../../assets/images/Avalanche.png"
export const Images = {
ethImg,bscImg,
  headerLogo,headerLogoAnalytics,transactionLogo,multiswapButtonLogo,firstCardLogo,secondCardLogo,thirdCardLogo,fourthCardLogo,userLogo,destinationLogo,ava,avalanche
};
